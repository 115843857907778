// @i18n-ignore-all
import actions from './custom-payment-action'
import { app as api } from '@ekuaibao/whispered'
import { fnShowOpenLinkModal } from './custom-payment-fetch-util'
export const Universal_Unique_Key = 'customPayment.pc'
export default [
  {
    id: '@custom-payment',
    reducer: () => Promise.resolve(actions.getReducer()),
    path: '/custom-payment',
    ref: '/',
    onload: () => import('./custom-payment-view'),
    'create:wallet'(data) {
      return api.dispatch(actions.postWalletOpen(data))
    },
    'get:CountryList'(data) {
      return api.dispatch(actions.getCountries(data))
    },
    'get:CityList'(data) {
      return api.dispatch(actions.getCities(data))
    },
    'open:link:modal': (url, fn) => {
      return fnShowOpenLinkModal(url, fn)
    },
  },

  {
    point: '@@layers',
    prefix: '@custom-payment',
    onload: () => import('./layers'),
  },

  {
    point: '@@menus',
    onload: () => [
      {
        id: 'custom-payment',
        pId: 'enterprise-manage',
        permissions: ['BANK_ACCOUNT_MANAGE', 'SYS_ADMIN'],
        weight: 9,
        label: '付款账户',
        href: '/custom-payment',
        icon: 'custom-payment-icon',
      },
    ],
  },
  {
    id: '@custom-pay-way-config',
    path: '/pay-way-config',
    ref: '/',
    onload: () => import('./PayWayConfig'),
  },
]
