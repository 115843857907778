/**
 * Created by xingbozhang on 17/5/23.
 */
import { Actions, node, reducer, action } from '@ekuaibao/store'
import { ID } from './key'
import { catchError } from '@ekuaibao/lib/lib/lib-util'
import { showMessage } from '@ekuaibao/show-util'
import ParseQuery2OrderSelect from '@ekuaibao/lib/lib/ParseQuery2OrderSelect'

import { Resource } from '@ekuaibao/fetch'
const accounts = new Resource('/api/pay/v1/accounts')
const newAccounts = new Resource('/api/pay/v2/accounts')
const banks = new Resource('/api/pay/v1/banks/branchesByKey')
const employees = new Resource('/api/v1/organization/staffs')
const savePayerConfig = new Resource('/api/pay/v2/payerinfoconfigs')
const wallets = new Resource('/api/pay/v2/wallets')
const countries = new Resource('/api/pay/v1/payeeInfoOversea/country')
const cities = new Resource('/api/pay/v1/payeeInfoOversea/city')

class PaymentAction extends Actions {
  static pluginName = ID

  @reducer(actionObj => actionObj.payload.items)
  @node('employeeList', [])
  @action
  getEmployeeList(data) {
    let query = ParseQuery2OrderSelect(data)
      .select(`walletOpenInfo(...),...`)
      .value()
    return {
      payload: employees.POST('/actives', query)
    }
  }

  @reducer(actionObj => actionObj.payload)
  @node('qyqbHistoryList', [])
  @action
  getHistoryList(data) {
    return {
      payload: wallets.POST('/wallets/details', data)
    }
  }

  /**
   * 保存全局配置
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => actionObj.payload)
  @action
  savePayerConfig(data) {
    return {
      payload: savePayerConfig.PUT('', data)
    }
  }

  /**
   * 启用
   * @param data
   * @returns {{payload: *}}
   */
  @reducer((actionObj, state) => {
    !actionObj.error ? showMessage.success(i18n.get('启用成功!')) : showMessage.error(actionObj.payload.message)
    return { ...state }
  })
  @action
  restore(data) {
    return {
      payload: newAccounts.PUT('/$id/restore', data)
    }
  }

  /**
   * 停用
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => {
    !actionObj.error ? showMessage.success(i18n.get('停用成功!')) : showMessage.error(actionObj.payload.message)
  })
  @action
  disable(data) {
    return {
      payload: newAccounts.PUT('/$id/disable', data)
    }
  }

  /**
   * 修改
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(
    catchError(actionObj => {
      !actionObj.error ? showMessage.success(i18n.get('修改成功!')) : showMessage.error(actionObj.payload.message)
    })
  )
  @action
  putPayment(data) {
    return {
      payload: newAccounts.PUT('/$id', data)
    }
  }

  /**
   * 创建
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(
    catchError(actionObj => {
      !actionObj.error ? showMessage.success(i18n.get('创建成功!')) : showMessage.error(actionObj.payload.message)
    })
  )
  @action
  postPayment(data) {
    return {
      payload: newAccounts.POST(data)
    }
  }

  /**
   * 企业钱包开通
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(null, err => {
    showMessage.error(err)
  })
  @action
  postWalletOpen(data) {
    return {
      payload: wallets.POST('/open', data)
    }
  }

  /**
   * 员工个人开通
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(
    catchError(actionObj => {
      !actionObj.error ? showMessage.success(i18n.get('提交成功!')) : showMessage.error(actionObj.payload.message)
    })
  )
  @action
  postStaffOpen(data) {
    return {
      payload: wallets.POST('/staff/open/$id', data)
    }
  }

  /**
   * 企业钱包余额
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(
    catchError(actionObj => {
      actionObj.error && showMessage.error(actionObj.payload.message)
    })
  )
  @action
  getWalletBalance(data) {
    return {
      payload: wallets.GET('/balance/$id', { id: data })
    }
  }
  @action
  getDimensions(data) {
    return {
      payload: dimensions.GET({ id: data })
    }
  }

  @reducer(catchError(actionObj => actionObj.payload))
  @action
  getPwdUrl(data) {
    return {
      payload: wallets.GET('/pwd/$id', { id: data })
    }
  }
  @reducer(catchError(actionObj => actionObj.payload))
  @action
  getRemarkSetting(data) {
    return {
      payload: newAccounts.GET('/getRemarkSetting/$id', { id: data })
    }
  }
  

  /**
   * 修改支付账户可见性
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => actionObj.payload)
  @action
  setAccountsVisibility(data) {
    return {
      payload: accounts.PUT('/$id/visibility', { ...data })
    }
  }
  /**
   * 银行网点模糊查询
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => actionObj.payload)
  @node('searchBanks', [])
  @action
  getBanks(data = { searchKey: '' }) {
    data.start = 0
    data.count = 100
    return {
      payload: banks.GET('', { ...data })
    }
  }

  /**
   * 支付账户所属国家
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => actionObj.payload)
  @node('searchCountries', [])
  @action
  getCountries(data) {
    return {
      payload: countries.GET('', { ...data })
    }
  }

  /**
   * 根据国家id获取支付款账号所属城市
   * @param data
   * @returns {{payload: *}}
   */
  @reducer(actionObj => actionObj.payload)
  @node('searchCities', [])
  @action
  getCities(data) {
    return {
      payload: cities.GET('/$id', { id: data })
    }
  }
}

export default new PaymentAction()
