/**************************************
 * Created By LinK On 2020/1/16 18:55.
 **************************************/
import { Resource } from '@ekuaibao/fetch';
import { showMessage, showModal } from '@ekuaibao/show-util';
import { getV } from '@ekuaibao/lib/lib/help';
import { app as api } from '@ekuaibao/whispered';
var antAlipay = new Resource('/api/pay/v2/channels/grant');
export var getAntAlipayState = function (id) {
    return antAlipay.GET('/$id', { id: id });
};
export var getUrlForBindAntAlipay = function (id) {
    return antAlipay.POST('/$id', { id: id });
};
//与支付宝确认绑定状态
export var fnConfirmAntAlipayStateFormAli = function (id) {
    //如果未绑定，弹窗引导用户绑定支付宝
    return getUrlForBindAntAlipay(id).then(function (res) {
        var items = getV(res, 'items', []);
        items.forEach(function (item) {
            var state = item.state, failureReason = item.failureReason, data = item.data;
            if (state === 'SUCCESS' && (data === null || data === void 0 ? void 0 : data.channel) === 'ANTALIPAY') {
                var channelState = data.state, reason = data.failureReason, url = data.url;
                if (channelState === 'PENDING' && url) {
                    //需要绑定，弹窗
                    fnShowOpenLinkModal({
                        url: url,
                        title: i18n.get('支付宝在线支付需要授权'),
                        desc: window.__PLANTFORM__ === 'WEIXIN'
                            ? i18n.get('由于企业微信限制，请复制此链接到浏览器上进行授权')
                            : i18n.get('请在已打开的页面完成授权，授权操作完成前请不要关闭此窗口。'),
                        okText: i18n.get('已完成'),
                        tip: i18n.get('支付完成前请不要关闭此窗口'),
                        callback: function () { return confirmAntAlipayBindState(id); }
                    });
                }
                else if (channelState === 'OCCUPIED') {
                    reason && showMessage.error(reason);
                }
                return;
            }
            failureReason && showMessage.error(failureReason);
        });
    }, function (err) {
        showMessage.error(err);
    });
};
//确定是否绑定成功
var confirmAntAlipayBindState = function (id) {
    return getAntAlipayState(id).then(function (res) {
        var items = getV(res, 'items', []);
        items.forEach(function (item) {
            var state = item.state, failureReason = item.failureReason, data = item.data;
            if (state === 'SUCCESS') {
                var channelState = data.state, reason = data.failureReason, channel = data.channel;
                if (channel !== 'ANTALIPAY')
                    return;
                if (channelState === 'SUCCESS') {
                    showMessage.success(i18n.get('支付宝账户授权成功'));
                }
                else {
                    showMessage.warning(reason ? reason : i18n.get('支付宝账户授权失败，请重试'));
                }
            }
            else {
                failureReason && showMessage.error(failureReason);
            }
        });
    }, function (err) {
        showMessage.error(err);
    });
};
//打开弹窗确认是否需要授权认证
export var fnShowOpenLinkModal = function (params) {
    var renderOpenLinkDesc = api.require('@audit/service-renderOpenLinkDesc').renderOpenLinkDesc;
    var url = params.url, fn = params.callback, title = params.title, desc = params.desc, okText = params.okText, tip = params.tip;
    if (window.__PLANTFORM__ === 'WEIXIN' && window.isWebchat) {
        showModal.info({
            content: renderOpenLinkDesc(title, desc, url, tip),
            okText: okText,
            onOk: function () {
                fn && fn();
            }
        });
    }
    else {
        //@ts-ignore
        api.emit('@vendor:open:link', url);
        showModal.info({
            content: renderOpenLinkDesc(title, desc),
            okText: okText,
            onOk: function () {
                fn && fn();
            }
        });
    }
};
//与易快报确认绑定状态
export var fnCheckAntAlipayBindStateFormEKB = function (id, fn) {
    getAntAlipayState(id).then(function (res) {
        var items = getV(res, 'items', []);
        items.forEach(function (item) {
            var state = item.state, failureReason = item.failureReason, data = item.data;
            if (state === 'SUCCESS' && (data === null || data === void 0 ? void 0 : data.channel) === 'ANTALIPAY') {
                var channelState = data.state, reason = data.failureReason;
                if (channelState === 'PENDING' || channelState === 'INIT') {
                    if (fn) {
                        //需要重新绑定，弹窗
                        showModal.confirm({
                            title: i18n.get('账户授权提醒'),
                            content: i18n.get('若使用支付宝作为该账户的支付方式，需要你对该账户进行在线授权'),
                            okText: i18n.get('现在授权'),
                            cancelText: i18n.get('放弃'),
                            onOk: function () {
                                fnConfirmAntAlipayStateFormAli(id);
                            },
                            onCancel: function () {
                                fn();
                            }
                        });
                    }
                    else {
                        fnConfirmAntAlipayStateFormAli(id);
                    }
                }
                else if (channelState === 'OCCUPIED') {
                    reason && showMessage.error(reason);
                }
                return;
            }
            failureReason && showMessage.error(failureReason);
        });
    }, function (err) {
        showMessage.error(err);
    });
};
